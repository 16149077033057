<template>
  <v-app dark>
    <v-app-bar app color="white" flat>
      <v-container fluid class="py-0 fill-height">
        <img src="https://www.tarion.com/themes/custom/tarion_theme/logo.svg" height="30" alt="Tarion" />
        <!-- <v-avatar
          class="mr-10 white--text"
          color="blue darken-1"
          size="50"
          text
        >
          TARION
        </v-avatar> -->

        <!-- <v-btn
          key="dashboard"
          to="dashboard"
          text
        >
          Dashboard
        </v-btn> -->

        <!-- <v-btn
          key="datasets"
          text
        >
          Datasets
        </v-btn> -->

        <v-spacer />

        <!-- <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
          />
        </v-responsive> -->
      </v-container>
    </v-app-bar>
    <v-main>
      <div class="container-wrap" style="min-height: 850px">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const usersNamespace = createNamespacedHelpers("users");

export default {
  components: {},
  data: () => ({ drawer: null }),
  computed: {},
  async mounted() {
    this.aInit();
    await this.$store.dispatch("aInit");
    // this.$store.commit('mSetLayout', 'iframe');
    const timeout = setTimeout(() => {
      const preloader = document.getElementById("preloader");
      if (preloader !== null || undefined) {
        preloader.remove();
      }
      clearTimeout(timeout);
    }, 500);
  },
  methods: {
    ...usersNamespace.mapActions(["aInit"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles";

@function section-margin($margin) {
  @return $margin * 20;
}
.main-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include palette-text-primary;
  .theme--dark & {
    background-color: $dark-background-default;
  }
  .theme--light & {
    background-color: $light-background-paper;
  }
}
.space-bottom {
  margin-bottom: section-margin($spacing1);
  @include breakpoints-down(md) {
    margin-bottom: section-margin(6px);
  }
  @include breakpoints-down(sm) {
    margin-bottom: section-margin($spacing1 * 0.5);
  }
}
.space-top {
  margin-top: section-margin($spacing1);
  @include breakpoints-down(md) {
    margin-top: section-margin(6px);
  }
  @include breakpoints-down(sm) {
    margin-top: section-margin($spacing1 * 0.5);
  }
}
.space-top-short {
  margin-top: section-margin($spacing1 * 0.5);
  @include breakpoints-down(sm) {
    margin-top: section-margin(2px);
  }
}
.space-bottom-short {
  margin-bottom: section-margin($spacing1 * 0.5);
  @include breakpoints-down(sm) {
    margin-bottom: section-margin(2px);
  }
}

.container-wrap {
  margin-top: -100px;
  > section {
    position: relative;
  }
  min-height: 700px;
  background-color: var(--v-header-bg);
}
</style>
